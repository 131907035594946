exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blogs-[id]-jsx": () => import("./../../../src/pages/blogs/[id].jsx" /* webpackChunkName: "component---src-pages-blogs-[id]-jsx" */),
  "component---src-pages-conditions-jsx": () => import("./../../../src/pages/Conditions.jsx" /* webpackChunkName: "component---src-pages-conditions-jsx" */),
  "component---src-pages-cookie-jsx": () => import("./../../../src/pages/cookie.jsx" /* webpackChunkName: "component---src-pages-cookie-jsx" */),
  "component---src-pages-eula-jsx": () => import("./../../../src/pages/eula.jsx" /* webpackChunkName: "component---src-pages-eula-jsx" */),
  "component---src-pages-features-jsx": () => import("./../../../src/pages/features.jsx" /* webpackChunkName: "component---src-pages-features-jsx" */),
  "component---src-pages-getting-started-jsx": () => import("./../../../src/pages/getting-started.jsx" /* webpackChunkName: "component---src-pages-getting-started-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-referral-program-jsx": () => import("./../../../src/pages/referral-program.jsx" /* webpackChunkName: "component---src-pages-referral-program-jsx" */),
  "component---src-pages-temp-jsx": () => import("./../../../src/pages/temp.jsx" /* webpackChunkName: "component---src-pages-temp-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */)
}

